<!-- 下单页面 -->
<template>
  <div class="box">
    <!-- 地址 -->
    <!-- <div class="topAddress">
			<div class="address">
				<div class="infoAddress">收货人:{{info.contact}}</div>
				<div class="telMy">
					<p>电话:{{info.tel}}</p>
					
				</div>
			</div>
    </div>-->
    <div class="topAddress">
      <div class="address">
        <div class="address-info" @click="selectAddress">
          <div class="user-info" v-if="userAddress">
            <span class="user-name">
              <span>联系人:</span>
              {{userAddress.name}}
            </span>
            <span class="user-phone">联系方式:{{userAddress.tel}}</span>
          </div>
          <div v-else>点击添加地址</div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model:show="show" cancel-text="取消" close-on-click-action @cancel="onCancel">
      <div class="content">
        <van-address-list
          v-model="chosenAddressId"
          :list="addressList"
          default-tag-text="默认"
          @add="addAddress"
          @edit="onEdit"
          @select="toggleAddress"
        />
      </div>
    </van-action-sheet>
    <!-- <div>
			<van-cell title="用餐时间" is-link value="11:11" />
			<van-cell title="用餐桌号" is-link value="2号桌" />
    </div>-->
    <!-- 商品 -->
    <div class="shopping">
      <div class="container">
        <!-- <div class="content display_flex flex-wrap_wrap"> -->
        <div class="content-item" v-for="(item, index) in info.products" :key="index">
          <ul class="display_flex flex-direction_column" v-show="isOpen || index < max">
            <li class="label">
              <div class="left">
                <img :src="item.image" alt />
                <div style="margin-left: 10px;">{{ item.name }}</div>
              </div>

              <div class="right">
                <p class="p1">￥{{item.price}}</p>
                <p class="p2">x{{item.count}}</p>
              </div>
            </li>
          </ul>
        </div>
        <!-- </div> -->
        <!-- <button class="btnMoer" v-show="!isOpen &&  info.products.length > max" @click="isOpen = !isOpen">展开更多</button> -->
        <!-- <button class="btnMoer" v-show="isOpen &&  info.products.length > max" @click="isOpen = !isOpen">收起</button> -->
      </div>
      <!-- 优惠券 -->
      <div>
        <van-cell
          title="优惠券"
          is-link
          :value="couponValue"
          @click="state.showList = true"
        />
        <!-- <van-coupon-cell
          :coupons="state.coupons"
          :chosen-coupon="state.chosenCoupon"
          @click="state.showList = true"
          v-if="coupon.length !=0"
        />-->
        <van-popup
          v-model:show="state.showList"
          round
          position="bottom"
          style="height: 90%; padding-top: 4px;"
        >
          <div class="coupon_list"  v-if="coupon.length !=0">
            <div
              class="coupon_list_bj u-border-bottom"
              v-for="(item, index) in coupon"
              :key="index"
            >
              <div class="coupon_list_bj_left">
                <div class="coupon_list_bj_left_num">
                  <text>￥</text>
                  {{item.consumer_reduce_money}}
                </div>
                <div class="coupon_list_bj_left_text">
                  <div
                    class="coupon_list_bj_left_text2"
                  >满{{item.consumer_full_money}}减{{item.consumer_reduce_money}}</div>
                  <div class="coupon_list_bj_left_text3">
                    <div class>活动时间</div>
                    <div class="time">{{item.use_began_date}} - {{item.use_end_date}}</div>
                  </div>
                </div>
              </div>
              <div class="coupon_list_bj_right">
                <div class="coupon_list_bj_right_use" @click="useCoupon(item)">去使用</div>
                <!-- <div class="coupon_list_bj_right_use" v-else>
							{{['待使用','已使用','已过期'][current]}}
                </div>-->
              </div>
            </div>
          </div>
		  <van-empty  description="暂无优惠券" v-else />
          <!-- <van-coupon-list
            :coupons="state.coupons"
            :chosen-coupon="state.chosenCoupon"
            :disabled-coupons="disabledCoupons"
            @change="onChange"
            @exchange="onExchange"
          />-->
        </van-popup>
      </div>
      <!-- <van-cell-group>
			  <van-cell title="满减优惠" value="-￥3" />
			</van-cell-group>
			<div style="text-align: right;margin-right: 20px;">
				<span>已优惠￥<span>3</span></span>
				<span>小计￥<span style="color: #FC6228;">{{info.total_price}}</span></span>
      </div>-->
    </div>
    <!--备注  -->
    <div class="rank">
      <van-field v-model="remark" label="备注" placeholder="备注口味，包装，配送要求等" input-align="right" />
    </div>
    <!-- 支付 -->
    <div class="pay">
      <p>
        合计:
        <span>￥{{info.total_price}}</span>
      </p>
      <button @click="goPay">支付</button>
    </div>
  </div>
</template>

<script>
import { buyConOrder, buySubOrder, getTN } from "@/api/order";
import { getUserAddressList } from "@/api/user";

import { userCouponLst } from "../../api/coupon";

import { reactive } from "vue";
import { Toast } from "vant";
const coupon = {
  available: 1,
  condition: "无使用门槛\n最多优惠12元",
  reason: "",
  value: 150,
  name: "优惠券名称",
  startAt: 1489104000,
  endAt: 1514592000,
  valueDesc: "1.5",
  unitDesc: "元",
};

export default {
  setup() {
    // const state = reactive({
    //   coupons: [coupon],
    //   showList: false,
    //   chosenCoupon: -1,
    // });

    // const onChange = (index) => {
    //   state.showList = false;
    //   state.chosenCoupon = index;
    // };
    // const onExchange = (code) => {
    //   state.coupons.push(coupon);
    // };
    // return {
    //   state,
    //   onChange,
    //   onExchange,
    //   disabledCoupons: [coupon],
    // };
  },
  data() {
    return {
      max: 2, // 默认最多显示的个数；最大行数*每行显示的个数 Number
      isOpen: false, // 是否展开全部信息的标识 Boolean 默认false
      info: {},
      remark: "",
      name: "",
      tel: "",
      userAddress: "",
      show: false,
      oid: "",
      money: "",
      coupon: [],
      numLength: "",
	  state:{
		  showList: false,
	  },
	  user_coupon_id:'',
	  couponValue:'',
    };
  },
  methods: {
    //编辑地址
    onEdit(item, index) {
      this.$router.push({
        path: "/h5/addAddress",
        query: {
          id: item.id,
        },
      });
    },
    //选择地址
    toggleAddress(item, index) {
      this.userAddress = Object.assign({}, this.userAddress, item);
      this.show = false;
    },
    //新增地址
    addAddress() {
      this.$router.push({
        path: "/h5/addAddress",
      });
    },
    onCancel() {
      this.$toast("取消");
    },
    selectAddress() {
      this.show = true;
    },
    //获取地址列表
    getUserAddressList() {
      getUserAddressList().then((res) => {
        console.log(res);
        let isDefaultNum = 0;
        if (res.data) {
          this.addressList = res.data;
          res.data.forEach((v, i) => {
            console.log(v.isDefault);
            if (v.isDefault) {
              isDefaultNum++;
              this.chosenAddressId = v.id;
              this.userAddress = v;
              console.log(v);
              console.log(this.chosenAddressId);
            }
          });
        }
        if (isDefaultNum == 0) {
          this.chosenAddressId = res.data[0].id;
          this.userAddress = res.data[0];
        }
        console.log(this.userAddress);
      });
    },
    // 详情
    buyConOrder() {
      let params = {
        pid: this.$route.query.id,
        count: 1,
      };
      buyConOrder(params).then((res) => {
        console.log(res);
        // if(res.code == 400){
        // 	Toast.success(res.msg);
        // 	setTimeout(() => {
        // 		this.$router.push({
        // 			path: '/orderPage'
        // 		})
        // 	}, 1000)
        // }
        this.info = res.data;
        this.oid = this.info.products[0].oid;
        this.money = this.info.products[0].price;
        console.log(this.info);
        console.log(this.info.products);
        this.userCouponLst();
      });
    },
    userCouponLst() {
      let params = {
        oid: this.oid,
        money: this.money,
      };
      userCouponLst(params).then((res) => {
        console.log(res);
        this.coupon = res.data.list;
		this.couponValue  = res.data.list.length+'张可用'
      });
    },
	useCoupon(item){
		console.log(item)
		this.state.showList = false
		this.user_coupon_id = item.id
		console.log(item.id)
		this.couponValue = '-'+item.consumer_reduce_money+'元'
	},
    // 获取订单号
    goPay() {
      console.log(this.$route.query.id);
      let params = {
        pid: this.$route.query.id,
        count: 1,
        name: this.userAddress.name,
        tel: this.userAddress.tel,
		user_coupon_id:this.user_coupon_id,
      };
      buySubOrder(params).then((res) => {
        console.log(res);
        this.getTN(res.data.order_num);
      });
    },
    getTN(num) {
      getTN({
        order_num: num,
      }).then((res) => {
        console.log(res);
        upsdk.pluginReady(function () {
          upsdk.pay({
            tn: res.data.tn,
            success: function () {
              // 支付成功, 开发者执行后续操作。
              console.log("成功");
              this.$toast("支付成功");
              setTimeout(() => {
                this.$router.push({
                  path: "/h5/orderPage",
                });
              }, 1000);
            },
            fail: function (err) {
              // 支付失败, err.msg 是失败原因描述, 比如TN号不合法, 或者用户取消了交易 等等。
              this.$toast(err.msg);
            },
          });
        });
      });
    },
  },
  mounted() {
    console.log(this.$route.query.id);
    this.buyConOrder();
    this.getUserAddressList();
  },
  watch: {},
};
</script>

<style lang="less" scoped>
::v-deep .van-address-item .van-radio__icon--checked .van-icon {
  background-color: #fc6228;
  border: solid 1px #fc6228;
}
::v-deep .van-button--danger {
  background-color: #fc6228;
  border: solid 1px #fc6228;
}
p {
  margin: 0;
  padding: 0;
}

.box {
  width: 100%;

  .topAddress {
    background: #fc6228;
    height: 120px;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    .address {
      width: 100%;
      height: 100%;
      background: #ffffff;
      border-radius: 5px;

      font-size: 14px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      padding: 0 10px;

      .address-info {
        display: flex;
        flex-direction: column;

        .user-info {
          font-size: 14px;
          color: #666666;

          display: flex;
          flex-direction: column;

          .user-name {
            margin-bottom: 10px;
          }
        }
      }

      .telMy {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 14px;
        }

        button {
          width: 61px;
          height: 28px;
          background: #fc6228;
          border-radius: 14px;
          border: none;
          color: #ffffff;
        }
      }
    }
  }

  //
  .shopping {
    font-size: 14px;
    background-color: #ffffff;
    margin: 15px 0;

    .container {
      padding: 10px;
      display: flex;
      flex-direction: column;

      .content-item {
        margin-bottom: 10px;

        .label {
          display: flex;
          justify-content: space-between;

          .left {
            display: flex;

            img {
              width: 60px;
              height: 60px;
              border-radius: 5px;
            }
          }

          .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .p1 {
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #1a1a1a;
            }

            .p2 {
              font-size: 11px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #999999;
            }
          }
        }
      }

      .btnMoer {
        border: none;
        background: #ffffff;
      }
    }
  }
  //
  .coupon_list {
    // width: 100%;
	padding: 15px;
    .coupon_list_bj {
      background-image: url(../../assets/image/coupon.png);
      width: 100%;
      height: 110px;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 2;
      margin-bottom: 15px;
      .mark {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
      }
      .coupon_list_bj_left {
        flex: 1;
        display: flex;
        align-items: center;
        .coupon_list_bj_left_num {
          width: 110px;
          padding-left: 20px;
          text-align: center;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #fe6e04;

          text {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #fe6e04;
          }
        }
        .coupon_list_bj_left_text {
          flex: 1;
          text-align: center;

          .coupon_list_bj_left_text1 {
            font-size: 10px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #fe6e04;
          }

          .coupon_list_bj_left_text2 {
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #fe6e04;
            margin: 5px 0;
          }

          .coupon_list_bj_left_text3 {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #fe6e04;
            .time {
              font-size: 12px;
            }
          }
        }
      }
    }
    .coupon_list_bj_right {
      width: 70px;
      // position: relative;
      // height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .coupon_list_bj_right_use {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #fe6e04;
      }
    }
  }
  //

  //
  .rank {
    margin-top: 10px;
  }

  //
  .pay {
    width: 100%;
    height: 51px;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin-left: 10px;

      span {
        color: #fc6228;
      }
    }

    button {
      margin-right: 10px;
      width: 87px;
      height: 34px;
      background: #fc6228;
      border-radius: 5px;
      border: none;
      color: #ffffff;
    }
  }
}
</style>
