import request from '@/utils/request'

// 优惠券列表
export function couponLst(params) {
	return request({
		url: '/coupon/lst',
		method: 'get',
        params
	})
}

// 优惠券领取
export function couponTake(params) {
	return request({
		url: '/coupon/take',
		method: 'get',
        params
	})
}

// 优惠券使用
export function userCouponLst(params) {
	return request({
		url: '/userCoupon/lst',
		method: 'get',
        params
	})
}